.players {
  .player {
    position: absolute;
    list-style: none;

    &.red {
      background-color: rgb(197, 17, 17);
    }

    &.blue {
      background-color: rgb(19, 46, 209);
    }

    &.green {
      background-color: rgb(17, 127, 45);
    }

    &.pink {
      background-color: rgb(237, 84, 186);
    }

    &.orange {
      background-color: rgb(239, 125, 14);
    }

    &.yellow {
      background-color: rgb(245, 245, 87);
    }

    &.black {
      background-color: rgb(63, 71, 78);
    }

    &.white {
      background-color: rgb(214, 224, 240);
    }

    &.purple {
      background-color: rgb(107, 48, 188);
    }

    &.brown {
      background-color: rgb(113, 73, 30);
    }

    &.cyan {
      background-color: rgb(56, 254, 220);
    }

    &.lime {
      background-color: rgb(80, 239, 57	);
    }

    &.me:after {
      content: ' (Me)';
    }
  }
}
